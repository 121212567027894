import React, { Component } from 'react';
import homeData from './page-data/home/page-data.json';
import './App.css';
import Marquee from 'react-double-marquee';

class App extends Component {
  constructor() {
    super();
    this.state = {
      data: undefined,
      language: window.location.pathname.startsWith('/en') ? 'en' : 'de',
      isDark: false,
      logos: []
    };
  }

  async componentDidMount() {
    let json = { data: undefined };
    this.setState({ data: homeData });
    this.setDark();

    if (json.data && json.data.page.title) {
      document.title = json.data.page.title;
    }

    setInterval(() => {
      this.setDark();
    }, 60000)
  }

  setDark = () => {
    const hour = new Date().getHours();
    const body = document.getElementsByTagName('body')[0];
    if (hour >= 21 || hour < 10) {
      this.setState({ isDark: true });
      body.className = 'dark';
    } else {
      this.setState({ isDark: false });
      body.style = 'transition: background-color ease-in 1s, color ease-in 1s;';
      body.className = '';
    }
  }

  addLogoForCoordinates = (event) => {
    const newLogo = { x: event.clientX - 100, y: event.clientY - 350, rotation: this.getRandomRotation()};
    this.setState(prevState => ({
      logos: [...prevState.logos, newLogo]
    }));
    event.stopPropagation();
  }

  removeLogos = () => {
    this.setState({ logos: [] });
  }

  getRandomRotation = () => {
    return Math.random() * 360;
  }

  render() {
    return this.state.data ? (
      <div className="app">
        <div className="content" onClick={this.removeLogos}>
          <main>
            <section className="welcome cursor">
              {this.state.data.top_marquee && this.state.data.top_marquee[this.state.language] ?
                <div className="top-marquee">
                  <Marquee direction="left" speed={this.state.data.top_marquee.speed} delay={1000}>
                    {this.state.data.top_marquee[this.state.language]}
                  </Marquee>
                </div>
                : ''
              }
              <article className={"LogoFontWrapper " + (this.state.data.top_marquee ? '' : 'with-margin')} >
                <h1 className="LogoFont">
                  {this.state.data.title}
                  {
                      this.state.logos.map((logo, i) => {
                        return (
                          <img key={ i }
                              style={
                                { 
                                  top: logo.y,
                                  left: logo.x,
                                  display: 'block',
                                  transform: `rotate(${logo.rotation}deg)`
                                }
                              }
                              src= {`img/icons/anze_hand_${this.state.isDark ? 'lachs' : 'gruen'}.svg`}
                              alt="hand" />
                        )
                      })
                    }
                  <div className="LogossPanel" onClick={this.addLogoForCoordinates}></div>
                </h1>
                <h1 className="LogoFont2">{this.state.data.short_title}</h1>
              </article>
              <section className="footer">
                <article className="welcomeintro">
                  <div>
                    <p>
                      {/* { this.state.data.description }
                      <br /><br /> */}
                      <a href={this.state.data.contact.address.link} target="_blank" rel="noopener noreferrer">
                        {this.state.data.contact.address.name}
                      </a>
                      <br />
                      {
                        this.state.data.opening_times.map((line, i) => {
                          return (<span key={i}>{line[this.state.language]}<br /></span>)
                        })
                      }
                    </p>
                  </div>
                </article>
                <article className="contactintro">
                  <p>
                    <a href={'mailto:' + this.state.data.contact.email} target="_blank" rel="noopener noreferrer">{this.state.data.contact.email}</a><br />
                    {
                      this.state.data.contact.social_networks.map((social_network, i) => {
                        return (<span key={i} className="social-network"><a href={social_network.link} target="_blank" rel="noopener noreferrer">{social_network.name}</a><span className="comma">,</span> </span>)
                      })
                    }
                  </p>
                </article>
              </section>
            </section>
          </main>
        </div>
      </div>
    ) : '';
  }
}

export default App;
